import React, { FC } from "react";
import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { OfferTemplate } from "@templates/OfferTemplate";
import { UniversalSection } from "@components/molecules/UniversalSection";
import { ChildImageSharp } from "@interfaces/childImageSharp";
import WhatWeCanDoMore from "@components/organisms/WhatWeCanDoMore";
import HtmlParser from "react-html-parser";
import { CreationProcesses } from "@components/atoms/CreationProcesses";
import styled from "styled-components";
import { stylesWrapper } from "@theme/commonStyles";
import { breakpoints } from "@theme/breakpoints";
import { SectionHeadline } from "@components/atoms/SectionHeadline";
import { ImageTextItem } from "@components/atoms/ImageTextItem";
import { ReactComponent as World } from "@vectors/worldwide.svg";

const StyledSectionHeadline = styled(SectionHeadline)`
  ${stylesWrapper};
  margin-top: 140px;
  margin-bottom: 0;

  ${breakpoints.tabletMax`
    margin-top: 100px;
  `}

  ${breakpoints.phoneMax`
    margin-top: 80px;
  `}
`;

const CreationProcessesWrapper = styled.div`
  ${stylesWrapper};
  margin-top: 100px;

  ${breakpoints.tabletMax`
    margin-top: 60px;
  `}

  ${breakpoints.phoneMax`
    margin-top: 40px;
  `}
`;

const StyledItemsWrapper = styled.section`
  ${stylesWrapper};
  margin-top: 140px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: -90px;

  ${breakpoints.tabletMax`
    flex-direction: column;
    margin-top: 100px;
    margin-bottom: -60px;
  `}

  ${breakpoints.phoneMax`
    margin-top: 80px;
    margin-bottom: -40px;
  `}
`;

const StyledItem = styled(ImageTextItem)`
  margin-bottom: 90px;
  width: 48%;
  max-width: 590px;

  ${breakpoints.tabletMax`
    width: 100%;
    max-width: unset;
    margin-bottom: 60px;
  `}

  ${breakpoints.phoneMax`
    margin-bottom: 40px;
  `}
`;

const ConstructionCompanyWebsite: FC<Props> = ({ data }) => {
  const { t } = useTranslation("offer");
  const { t: ct } = useTranslation("common");

  const vectorsAndImages = [World, "/calendar.png"];

  return (
    <OfferTemplate
      title={ct("title-offer-construction-company-website")}
      description={ct("description-offer-construction-company-website")}
      heroTitle={t("construction-company-website.hero.title")}
      heroDescription={t("construction-company-website.hero.description", {
        returnObjects: true,
      })}
      heroImage={data.hero.childImageSharp.gatsbyImageData}
    >
      <UniversalSection
        title={t("construction-company-website.sections.0.title")}
        description={t("construction-company-website.sections.0.description", {
          returnObjects: true,
        })}
        images={[data.firstSectionImage.childImageSharp.gatsbyImageData]}
        fromRight
      />
      <UniversalSection
        title={t("construction-company-website.sections.1.title")}
        description={t("construction-company-website.sections.1.description", {
          returnObjects: true,
        })}
        images={[data.secondSectionImage.childImageSharp.gatsbyImageData]}
      />
      <UniversalSection
        title={t("construction-company-website.sections.2.title")}
        description={t("construction-company-website.sections.2.description", {
          returnObjects: true,
        })}
        images={[data.thirdSectionImage.childImageSharp.gatsbyImageData]}
        fromRight
      />
      <UniversalSection
        title={t("construction-company-website.sections.3.title")}
        description={t("construction-company-website.sections.3.description", {
          returnObjects: true,
        })}
        images={[data.fourthSectionImage.childImageSharp.gatsbyImageData]}
      />
      <UniversalSection
        title={t("construction-company-website.sections.4.title")}
        description={t("construction-company-website.sections.4.description", {
          returnObjects: true,
        })}
        fromRight
        images={[data.fifthSectionImage.childImageSharp.gatsbyImageData]}
      />

      <StyledItemsWrapper>
        {vectorsAndImages.map((vector, index) => (
          <StyledItem
            key={index}
            vector={vector}
            name={t(
              `construction-company-website.sections.5.items.${index}.title`
            )}
            description={t(
              `construction-company-website.sections.5.items.${index}.description`
            )}
          />
        ))}
      </StyledItemsWrapper>

      <StyledSectionHeadline>
        {HtmlParser(t("construction-company-website.sections.how-we-work"))}
      </StyledSectionHeadline>

      <CreationProcessesWrapper>
        <CreationProcesses withoutNavigation group={0} />
      </CreationProcessesWrapper>

      <WhatWeCanDoMore exclude={8} />
    </OfferTemplate>
  );
};

interface Props {
  data: {
    hero: ChildImageSharp;
    firstSectionImage: ChildImageSharp;
    secondSectionImage: ChildImageSharp;
    thirdSectionImage: ChildImageSharp;
    fourthSectionImage: ChildImageSharp;
    fifthSectionImage: ChildImageSharp;
  };
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    hero: file(name: { eq: "construction-company-website_hero" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, placeholder: BLURRED)
      }
    }

    firstSectionImage: file(name: { eq: "construction-company-website_0" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, placeholder: BLURRED)
      }
    }

    secondSectionImage: file(name: { eq: "construction-company-website_1" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, placeholder: BLURRED)
      }
    }

    thirdSectionImage: file(name: { eq: "construction-company-website_2" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, placeholder: BLURRED)
      }
    }

    fourthSectionImage: file(name: { eq: "construction-company-website_3" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, placeholder: BLURRED)
      }
    }

    fifthSectionImage: file(name: { eq: "construction-company-website_4" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, placeholder: BLURRED)
      }
    }
  }
`;

export default ConstructionCompanyWebsite;
